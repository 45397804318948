import React from "react";
import styled from "styled-components";
import header from "../../images/header.png";

function Landing_Page() {
  return (
    <div id="home">
      <Landing>
        <div className="container pt-5 pt-md-0 mt-5">
          <div className="container-border d-md-flex justify-content-md-center">
            <div className="col-md-6 col-sm-12">
              <h2 className="mt-5">In loving memory of</h2>
              <h1>Martina Cullen</h1>
              <h2>1973 - 2021</h2>
            </div>

            <div className="col-md-6 col-sm-12 text-center">
              <img className="img-fluid" src={header} alt="" />
            </div>
          </div>
        </div>
      </Landing>

      <ContainerBottom></ContainerBottom>
    </div>
  );
}

const Landing = styled.div`
  background-color: #222222;
  color: #f5f5f5;
  padding: 40px 0 80px 0;
  z-index: 11 !important;

  .container-border {
    width: 100%;
    height: 700px;
    border: 3px solid #f2b933;
    border-radius: 25px;
    padding: 100px 40px;

    h1 {
      color: #f2b933;
      font-weight: 600;
      font-size: clamp(40px, 5vw, 70px);
      padding: 20px 0px;
    }

    h2 {
      font-size: clamp(40px, 5vw, 45px);
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    .img-fluid {
      margin-top: 50px;
    }
    .container-border {
      padding: 20px 20px;
      height: auto;
    }
  }
`;

const ContainerBottom = styled.div`
  width: 100%;
  height: 120px;
  background-color: #222222;
  z-index: 1;
  transform: skewY(-1deg);
  margin-top: -60px;
`;

export default Landing_Page;
