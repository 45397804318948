import "./App.css";
import LandingPage from "./Components/Landing_Page/Landing_Page";
import Navbar from "./Components/Navbar/Navbar";
import About from "./Components/About/About";
import Gallery from "./Components/Gallery/Gallery";
import Footer from "./Components/Footer/Footer";
import Condolence from "./Components/Condolences/Condolence";

function App() {
  return (
    <div>
      <Navbar />
      <LandingPage />
      <About />
      <Gallery />
      <Condolence />
      <Footer />
    </div>
  );
}

export default App;
