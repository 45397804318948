import React from "react";
import styled from "styled-components";
import Martina from "../../images/Martina.png";

function About() {
  return (
    <AboutPage id="about">
      <div className="container pt-5 mt-5 pt-md-0">
        <div className="container-border d-md-flex justify-content-md-center">
          <div className="col-md-6 col-sm-12">
            <img className="img-fluid" src={Martina} alt="" />
          </div>

          <div className="col-md-6 col-sm-12">
            <h1>
              <span>About</span> Martina
            </h1>
            <p>
              Martina was a devoted mother to her two children Christopher and
              Lauren, She adored her partner John.
            </p>

            <p>
              Martina loved to spend her time in her garden with her 2 dogs
              Bobby and Jess, she would sit in the kitchen watching Holby City
              with a cup of coffee and our cat Jack on her lap.
            </p>

            <p>
              Martina loved gardening, she did it as her job and she was very
              passionate about it which was evident in her work.
            </p>

            <p>
              Martina could do anything she put her mind too, she was always
              fixing things, upcycling furniture and making stuff out of
              reclaimed wood.
            </p>

            <p>Martina is mourned by her friends and family.</p>

            <p>
              <strong>May she Rest In Peace</strong>
            </p>
          </div>
        </div>
      </div>
    </AboutPage>
  );
}

const AboutPage = styled.div`
  padding-top: 100px;

  .container-border {
    width: 100%;
    border: 3px solid #f2b933;
    border-radius: 25px;
    padding: 40px 40px;

    h1 {
      color: #f2b933;
      font-weight: 600;
      font-size: 70px;
      padding: 20px 0px;
    }

    span {
      color: #2a2a2a;
    }

    strong {
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

export default About;
