import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Form from "./Form";
import Axios from "axios";

function Condolence() {
  const [posts, setPost] = useState([]);

  useEffect(() => {
    Axios.get("https://martina-cullen-memorial.herokuapp.com/comments")
      .then((res) => setPost(res.data))
      .catch((error) => console.log(error));
  }, []);
  return (
    <Condolences id="condolence">
      <div className="container">
        <div className="container-border">
          <h1 className="text-center">Condolences</h1>
          <div className="row">
            <div className="col-md-6 col-sm-12 mt-5">
              {!posts.length ? (
                <div className="loader">
                  <article>
                    <h4>There is currently no messages at this time.</h4>
                  </article>
                </div>
              ) : (
                posts.map((post, key) => (
                  <article className="mt-5 mb-5">
                    <strong>{post.name}</strong>
                    <p>{post.message}</p>
                  </article>
                ))
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </Condolences>
  );
}

const Condolences = styled.div`
  padding: 100px 0px;

  .container-border {
    width: 100%;
    border: 3px solid #f2b933;
    border-radius: 25px;
    padding: 40px 40px;

    h1 {
      color: #f2b933;
      font-weight: 600;
      font-size: clamp(40px, 10vw, 70px);
      padding: 20px 0px 0px;
    }

    strong {
      color: #f2b933;
    }
  }
`;

export default Condolence;
