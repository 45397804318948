import React from "react";
import styled from "styled-components";

import pic1 from "../../images/gallery/x9ndncf4.png";
import pic2 from "../../images/gallery/hqklfsrm.png";
import pic3 from "../../images/gallery/icdjuwiz.png";
import pic4 from "../../images/gallery/qws3btds.png";

import pic5 from "../../images/gallery/zxymc9pa.png";
import pic6 from "../../images/gallery/2q8xl9as.png";
import pic7 from "../../images/gallery/5g44cz53.png";
import pic8 from "../../images/gallery/gctlsz5p.png";
import pic9 from "../../images/gallery/9va66lbt.png";

import pic10 from "../../images/gallery/m0mtlody.png";
import pic11 from "../../images/gallery/alpvdeif.png";
import pic12 from "../../images/gallery/8xb9b6q4.png";
import pic13 from "../../images/gallery/x4wf9etf.png";

function MyGallery() {
  return (
    <div>
      <ContainerTop id="gallery"></ContainerTop>
      <GalleryPage>
        <div className="container pt-5 mt-5 pt-md-0 container-border">
          <h1 className="text-center">Gallery</h1>
          <div className="d-md-flex justify-content-md-center">
            <div className="col-md-3 col-sm-12">
              <img
                className="img-fluid mb-4"
                src={pic1}
                alt="Martina and lauren"
              />
              <img
                className="img-fluid mb-4"
                src={pic2}
                alt="Martina and John"
              />
              <img
                className="img-fluid mb-4"
                src={pic3}
                alt="Martina and John"
              />
              <img
                className="img-fluid mb-4"
                src={pic4}
                alt="Martina on a motorcycle"
              />
            </div>
            <div className="col-md-3 col-sm-12 spacer">
              <img
                className="img-fluid mb-4"
                src={pic5}
                alt="Young martina with jason"
              />
              <img
                className="img-fluid mb-4"
                src={pic6}
                alt="Martina with a leafblower"
              />
              <img
                className="img-fluid mb-4"
                src={pic7}
                alt="Martina, Christopher and lauren"
              />
              <img className="img-fluid mb-4" src={pic8} alt="" />
              <img className="img-fluid mb-4" src={pic9} alt="" />
            </div>
            <div className="col-md-3 col-sm-12 ">
              <img className="img-fluid mb-4" src={pic10} alt="" />
              <img className="img-fluid mb-4" src={pic11} alt="" />
              <img className="img-fluid mb-4" src={pic12} alt="" />
              <img className="img-fluid mb-4" src={pic13} alt="" />
            </div>
          </div>
        </div>
      </GalleryPage>

      <ContainerBottom></ContainerBottom>
    </div>
  );
}

const GalleryPage = styled.div`
  background-color: #222222;
  padding: 10px 15px 200px 15px;
  z-index: 4;

  .container-border {
    width: 100%;
    border: 3px solid #f2b933;
    border-radius: 25px;
    padding: 100px 40px;

    h1 {
      color: #f2b933;
      font-weight: 600;
      font-size: clamp(40px, 10vw, 70px);
      text-align: center;
      padding: 20px 0px;
    }
    .spacer {
      margin: 0 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;

    .spacer {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const ContainerBottom = styled.div`
  width: 100%;
  height: 100px;
  background-color: #222222;
  z-index: 2;
  transform: skewY(-1deg);
  margin-top: -60px;
`;

const ContainerTop = styled.div`
  width: 100%;
  height: 100px;
  background-color: #222222;
  z-index: 3;
  transform: skewY(-1deg);
  transform-origin: top right;
  margin-top: 100px;
`;

export default MyGallery;
