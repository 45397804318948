import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

function Form() {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const HandleEvent = (e) => {
    e.preventDefault();

    setName("");
    setMessage("");

    const words = {
      name,
      message,
    };

    axios
      .post("https://martina-cullen-memorial.herokuapp.com/comments/add", words)
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  return (
    <FormContainer className="mt-5">
      <form onSubmit={HandleEvent}>
        <div class="mb-3">
          <label class="form-label">Name</label>
          <input
            type="text"
            class="form-control"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>

        <div class="mb-3">
          <label class="form-label">Message</label>
          <textarea
            class="form-control"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </div>

        <button type="submit" class="btn btn-yellow col-12">
          Submit
        </button>
      </form>
    </FormContainer>
  );
}

const FormContainer = styled.div`
  padding: 50px 0;

  textarea {
    height: 200px;
  }

  .btn-yellow {
    background-color: #f2b933;
  }
`;

export default Form;
