import React from "react";
import styled from "styled-components";

function Footer() {
  return (
    <FooterContainer>
      <div className="container text-center">
        Designed with <span>❤</span> by Christopher Cullen
      </div>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  padding: 20px;
  background-color: #222222;
  color: #f5f5f5;
`;

export default Footer;
