import React from "react";
import styled from "styled-components";

function Navbar() {
  return (
    <Nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container">
        <a className="navbar-brand" href="/">
          Martina <span>Cullen</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#gallery">
                Gallery
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#condolence">
                Condolences
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Nav>
  );
}

const Nav = styled.nav`
  position: fixed;
  width: 100%;
  background-color: #222222;
  z-index: 10;

  .nav-item {
    padding: 0 20px;
  }

  a {
    color: #f5f5f5;
  }

  a:hover {
    color: #f5f5f5;
  }

  span {
    color: #f2b933;
  }

  @media only screen and (max-width: 768px) {
    position: relative;
  }
`;

export default Navbar;
